/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

export type GetCoverageDistrictsIdBrigadesSort =
  (typeof GetCoverageDistrictsIdBrigadesSort)[keyof typeof GetCoverageDistrictsIdBrigadesSort];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCoverageDistrictsIdBrigadesSort = {
  brigadeNameAsc: "brigadeNameAsc",
  brigadeNameDesc: "brigadeNameDesc",
} as const;
