/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

export type GetIncidentsSort =
  (typeof GetIncidentsSort)[keyof typeof GetIncidentsSort];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetIncidentsSort = {
  alertLevelAsc: "alertLevelAsc",
  alertLevelDesc: "alertLevelDesc",
  burntAreaAsc: "burntAreaAsc",
  burntAreaDesc: "burntAreaDesc",
  fireClassAsc: "fireClassAsc",
  fireClassDesc: "fireClassDesc",
  lgaAsc: "lgaAsc",
  lgaDesc: "lgaDesc",
  nameAsc: "nameAsc",
  nameDesc: "nameDesc",
  priorityAsc: "priorityAsc",
  priorityDesc: "priorityDesc",
  statusAsc: "statusAsc",
  statusDesc: "statusDesc",
  updatedAtAsc: "updatedAtAsc",
  updatedAtDesc: "updatedAtDesc",
  lastPredictionFinishedAtAsc: "lastPredictionFinishedAtAsc",
  lastPredictionFinishedAtDesc: "lastPredictionFinishedAtDesc",
} as const;
