/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

export type GetSocialFeedSort =
  (typeof GetSocialFeedSort)[keyof typeof GetSocialFeedSort];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSocialFeedSort = {
  lastIngestedAtAsc: "lastIngestedAtAsc",
  lastIngestedAtDesc: "lastIngestedAtDesc",
  priorityScoreAsc: "priorityScoreAsc",
  priorityScoreDesc: "priorityScoreDesc",
} as const;
