/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

export type GetSocialFiltersSort =
  (typeof GetSocialFiltersSort)[keyof typeof GetSocialFiltersSort];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSocialFiltersSort = {
  keywordAsc: "keywordAsc",
  keywordDesc: "keywordDesc",
  activeAsc: "activeAsc",
  activeDesc: "activeDesc",
} as const;
