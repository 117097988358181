import {
  AlertLevelAdvice,
  AlertLevelEmergencyWarning,
  AlertLevelNoAlert,
  AlertLevelOtherFire,
  AlertLevelPending,
  AlertLevelPlannedBurn,
  AlertLevelResponding,
  AlertLevelWatchAndAct,
} from "@app/design-system";
import type { Incident } from "../../.rest-hooks/types";
import type { IncidentAlertLevel } from "../../.rest-hooks/types/incidents.yml";

export type AlertLevelKeys = IncidentAlertLevel | "Pending" | "Responding";

export const AlertLevelIconMap: Record<AlertLevelKeys, ReactSVGComponent> = {
  Advice: AlertLevelAdvice,
  "Emergency Warning": AlertLevelEmergencyWarning,
  None: AlertLevelNoAlert,
  Other: AlertLevelOtherFire,
  Pending: AlertLevelPending,
  "Planned Burn": AlertLevelPlannedBurn,
  Responding: AlertLevelResponding,
  "Watch and Act": AlertLevelWatchAndAct,
} as const;

export const AlertLevelIconIdMap: Record<AlertLevelKeys, string> = {
  Advice: "alert-level-advice",
  "Emergency Warning": "alert-level-emergency-warning",
  None: "alert-level-no-alert",
  Other: "alert-level-other-fire",
  Pending: "alert-level-pending",
  "Planned Burn": "alert-level-planned-burn",
  Responding: "alert-level-responding",
  "Watch and Act": "alert-level-watch-and-act",
} as const;

export function getAlertLevelIcon(
  alertLevel?: AlertLevelKeys | null,
): ReactSVGComponent {
  const level = getAlertLevelLabel(alertLevel);

  return AlertLevelIconMap[level];
}

export function getAlertLevelIconName(
  alertLevel: AlertLevelKeys | undefined | null,
) {
  const level = getAlertLevelLabel(alertLevel);

  return AlertLevelIconIdMap[level];
}

export function getAlertLevelLabel(
  alertLevel: AlertLevelKeys | undefined | null,
): AlertLevelKeys {
  if (!alertLevel) return "None";

  return alertLevel in AlertLevelIconIdMap ? alertLevel : "Other";
}

export function getAlertLevelFromIncident(incident: Incident): AlertLevelKeys {
  return incident.attributes.status === "RESPONDING"
    ? "Responding"
    : incident.attributes.alertLevel;
}
