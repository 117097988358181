/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type { GetPermissions200 } from "./types";
import type { JSONAPIErrorResponse } from "./types/jsonapi.yml";

/**
 * @summary Get the list of permissions
 */
export const getPermissions = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetPermissions200>> => {
  return axios.get(`/permissions`, options);
};

export const getGetPermissionsQueryKey = () => {
  return [`/permissions`] as const;
};

export const getGetPermissionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPermissions>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getPermissions>>, TError, TData>
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPermissionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPermissions>>> = ({
    signal,
  }) => getPermissions({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPermissions>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetPermissionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPermissions>>
>;
export type GetPermissionsQueryError = AxiosError<JSONAPIErrorResponse>;

export function useGetPermissions<
  TData = Awaited<ReturnType<typeof getPermissions>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getPermissions>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getPermissions>>,
        TError,
        TData
      >,
      "initialData"
    >;
  axios?: AxiosRequestConfig;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetPermissions<
  TData = Awaited<ReturnType<typeof getPermissions>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getPermissions>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getPermissions>>,
        TError,
        TData
      >,
      "initialData"
    >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPermissions<
  TData = Awaited<ReturnType<typeof getPermissions>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getPermissions>>, TError, TData>
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get the list of permissions
 */

export function useGetPermissions<
  TData = Awaited<ReturnType<typeof getPermissions>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getPermissions>>, TError, TData>
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetPermissionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
